import React from 'react';
import { List, Datagrid, TextField } from 'react-admin'

const DocumentList = props => (
  <List {...props}>
      <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="file._name" label="Nome do Arquivo" />
          <TextField source="template" label="Tipo" />
          <TextField source="jobStatus" label="Estado do Processamento" />
          <TextField source="indexes.cpf" label="CPF" />
          <TextField source="indexes.mesAno" label="Mês/Ano" />
      </Datagrid>
  </List>
);

export default DocumentList;