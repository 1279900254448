import React, { useCallback, useState } from 'react';
import { Create, SimpleForm, FileInput, FileField, SelectInput, SaveButton, Toolbar, useTranslate, useCreate, useRedirect, useNotify, useMutation, LinearProgress } from 'react-admin';
import { useForm } from 'react-final-form';
import Parse from 'parse';

const CSVImport = (props) => (
  <Create {...props}>
    <SimpleForm toolbar={<CSVImportToolbar />} redirect="show">
      <SelectInput
        initialValue="colaboradores"
        source="type"
        label="Tipo de Importação"
        choices={[
          { id: 'colaboradores', name: 'Colaboradores' }
        ]}
      />
    
      <FileInput source="file" label="Colaboradores (cpf, nome, email, telefone, celular)">
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
  );

const CSVImportSaveButton = ({ handleSubmitWithRedirect, onSubmitForm, ...props }) => {
  const form = useForm();
  // const [create, { loading }] = useCreate('Documentos');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [maxProgress, setMaxProgress] = useState(0);
  const [mutate] = useMutation();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const translate = useTranslate();

  const handleClick = useCallback(async () => {
    const { file, type } = form.getState().values;
    let hasError = false;
    setLoading(true);
    setMaxProgress(1);
    setProgress(0);
    // for(const file of files) {
    setProgress(p => p+1);
    try {
      const parseFile = new Parse.File(file.rawFile.name, file.rawFile);
      await parseFile.save();
      const importDoc = new (Parse.Object.extend('Import'))();
      importDoc.set('type', type);
      importDoc.set('file', parseFile);
      await importDoc.save();
      await mutate({
        type: 'create',
        resource: 'Import',
        payload: {
          data: importDoc.toJSON()
        }
      })
    } catch(error) {
      hasError = true;
      console.log('upload error', error);
      notify(`Erro ao realizar upload do arquivo '${file.rawFile.name}. Tente reenviar esse arquivo.`, 'warning');
      // break;
    }
    // }
    setLoading(false);
    if(!hasError) {
      notify(`Importação feita com sucesso. Verifique o resultado completo na listagem de importações.`)
      redirectTo('/Import')
    }
  }, [form]);

  return (
    <>
    <SaveButton {...props} 
      label={translate(loading ? "ra.page.loading" : "ra.action.confirm")}
      disabled={loading}
      handleSubmitWithRedirect={handleClick} 
    />
    {loading && <><LinearProgress style={{ marginLeft: 16 }} /> <span>{progress}/{maxProgress}</span></>}
    </>
  )
}

const CSVImportToolbar = props => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();

  const onSubmitForm = async (form) => {
    setLoading(true);
    // const response = await Parse.Cloud.run('uploadDocuments', form.getState().values);
    // console.log('parse response', response);
    setLoading(false);
  }

  return (
    <Toolbar {...props}>
      <CSVImportSaveButton
        redirect="/Colaborador"
        submitOnEnter={true}
        disabled={loading}
        onSubmitForm={onSubmitForm}
      />
    </Toolbar>
  );
}
  
  export default CSVImport;