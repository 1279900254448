import React, { useCallback, useState } from 'react';
import { Create, SimpleForm, FileInput, FileField, SelectInput, SaveButton, Toolbar, useTranslate, useCreate, useRedirect, useNotify, useMutation, LinearProgress } from 'react-admin';
import { useForm } from 'react-final-form';
import Parse from 'parse';

const DocumentUpload = (props) => (
  <Create {...props}>
    <SimpleForm toolbar={<DocumentUploadToolbar />} redirect="show">
      <SelectInput
        initialValue="contracheque"
        source="template"
        choices={[
          { id: 'contracheque', name: 'Contra-cheque' }
        ]}
      />
    
      <FileInput multiple source="files" label="Contra-cheques (PDF)" accept="application/pdf">
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
  );

const DocumentUploadSaveButton = ({ handleSubmitWithRedirect, onSubmitForm, ...props }) => {
  const form = useForm();
  // const [create, { loading }] = useCreate('Documentos');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [maxProgress, setMaxProgress] = useState(0);
  const [mutate] = useMutation();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const translate = useTranslate();

  const handleClick = useCallback(async () => {
    const { files, template } = form.getState().values;
    let hasError = false;
    setLoading(true);
    setMaxProgress(files.length);
    setProgress(0);
    for(const file of files) {
      setProgress(p => p+1);
      try {
        const parseFile = new Parse.File(file.rawFile.name, file.rawFile);
        await parseFile.save();
        await mutate({
          type: 'create',
          resource: 'Documentos',
          payload: {
            data: { 
              template,
              file: parseFile.toJSON()
            }
          }
        })
      } catch(error) {
        hasError = true;
        console.log('upload error', error);
        notify(`Erro ao realizar upload do arquivo '${file.rawFile.name}. Tente reenviar esse arquivo.`, 'warning');
        break;
      }
    }
    setLoading(false);
    if(!hasError) {
      notify(`Documentos enviados com sucesso. Verifique o status do processamento na listagem.`)
      redirectTo('/Documentos')
    }
  }, [form]);

  return (
    <>
    <SaveButton {...props} 
      label={translate(loading ? "ra.page.loading" : "ra.action.confirm")}
      disabled={loading}
      handleSubmitWithRedirect={handleClick} 
    />
    {loading && <><LinearProgress style={{ marginLeft: 16 }} /> <span>{progress}/{maxProgress}</span></>}
    </>
  )
}

const DocumentUploadToolbar = props => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();

  const onSubmitForm = async (form) => {
    setLoading(true);
    const response = await Parse.Cloud.run('uploadDocuments', form.getState().values);
    console.log('parse response', response);
    setLoading(false);
  }

  return (
    <Toolbar {...props}>
      <DocumentUploadSaveButton
        redirect="/Documentos"
        submitOnEnter={true}
        disabled={loading}
        onSubmitForm={onSubmitForm}
      />
    </Toolbar>
  );
}
  
  export default DocumentUpload;