import React, { useState } from 'react';
import { useLogin, useNotify, Notification, useAuthState, useTranslate, useAuthenticated } from 'react-admin';
import "./Login.css";
import { Button, TextField } from '@material-ui/core';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import theme from '../styles/theme';

const LoginPage = () => {
  useAuthenticated();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { loading } = useAuthState();
  const login = useLogin();
  const notify = useNotify();
  const translate = useTranslate();

  const onLoginButton = async (e) => {
    e.preventDefault();
      login({ username, password })
        .catch(() => notify(translate('ra.auth.sign_in_error')));
  }

  return (
    <MuiThemeProvider theme={theme}>
      <div className="login_bg">
        <div className="login_card">
          <div className="login_card_logo_container">
            <img src="/885eb8baac324f0ba3c3c3a6618fbebc.png" />
          </div>
          <div className="login_card_body">
            <TextField 
              label={translate('ra.auth.username')} 
              variant="filled" 
              onChange={e => setUsername(e.target.value)}
              />
            <TextField 
              label={translate('ra.auth.password')} 
              variant="filled" 
              style={{ marginTop: 20 }}
              type="password"
              onChange={e => setPassword(e.target.value)}
            />

            <Notification />

            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: 20 }}
              onClick={onLoginButton}
              disabled={!username || username.length == 0 || !password || password.length == 0 || loading}
            >
              {loading ? translate('ra.page.loading') : translate('ra.auth.sign_in')}
            </Button>
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  )
}

export default LoginPage;