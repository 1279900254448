import { createMuiTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#008ABE', contrastText: '#ffffff' },
    secondary: { main: '#008ABE', contrastText: '#ffffff' },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  }
});

export default theme;