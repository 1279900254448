import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNotify, Notification } from 'react-admin';
import { Button, TextField, Typography, CardContent, CardActions, Card, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import theme from '../styles/theme';
import InputMask from "react-input-mask";
import isCpf from 'iscpf'
import Parse from 'parse';

const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
    overflow: 'hidden',
    width: '100%',
    padding: 0,
    margin: 0,
    // backgroundColor: theme.palette.secondary.main
    background: 'linear-gradient(180deg, rgba(0,138,190,1) 30%, rgba(104,116,157,0.9976365546218487) 100%)'
  },
  root: {
    minWidth: 300
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  centerFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textDescription: {
    marginTop: 20
  },
  logo: {
    marginBottom: 32,
    marginTop: 32
  },
  textField: {
    marginTop: 20
  }
});


const isCellphoneValid = (cellphone) => /\d{11}/.test(cellphone);///ˆ\(?\d\d\)?\s?\d?\s?\d{4}-?\d{4}$/.test(cellphone) || 

const isCpfValid = (cpf) => isCpf(cpf);

const isEmailValid = (email) => /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(email);

export default function DownloadPage() {
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [errors, setErrors] = useState({});
  const [step, setStep] = useState('initial');
  const [disabledAction, setDisabledAction] = useState(false);
  const [disabledFields, setDisabledFields] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const notify = useNotify();
  const recaptchaRef = useRef();

  const onRecaptcha = (response) => {
    const celular = "+55" + cellphone.replace(/\D/ig, '');
    window.firebase.auth().languageCode ="pt";
    window.firebase.auth().signInWithPhoneNumber(celular, recaptchaRef.current)
      .then(function (confirmationResult) {
        setDisabledAction(false);
        setStep('sms');
        window.confirmationResult = confirmationResult;
      }).catch(function (error) {
        notify('Houve um erro ao disparar o SMS. Atualize a página e tente novamente.', 'warning')
      });
  }

  const getCellphoneError = () => {
    const cleanCellphone = cellphone.replace(/\D/ig, '');
    if(isCellphoneValid(cleanCellphone)) {
      return null;
    } else {
      return 'Verifique seu celular e tente novamente.';
    }
  }

  const updateCellphone = (newValue) => {
    setErrors({ ...errors, cellphone: null });
    setCellphone(newValue);
  }

  const getCpfError = () => {
    const cleanCpf = cpf.replace(/\D/ig, '');
    if(isCpfValid(cleanCpf)) {
      return null;
    } else {
      return 'Verifique o seu CPF e tente novamente.';
    }
  }

  const updateCpf = (newValue) => {
    setErrors({ ...errors, cpf: null });
    setCpf(newValue);
  }

  const getEmailError = () => {
    if(isEmailValid(email)) {
      return null;
    } else {
      return 'Verifique seu email e tente novamente.';
    }
  }

  const updateEmail = (newValue) => {
    setErrors({ ...errors, email: null });
    setEmail(newValue);
  }

  const updateSmsCode = (newValue) => {
    setErrors({ ...errors, smsCode: null });
    setSmsCode(newValue);
  }

  const onSendButtonInitialStep = () => {
    const emailError = getEmailError();
    const cpfError = getCpfError();
    const cellphoneError = getCellphoneError();
    
    if(emailError || cpfError || cellphoneError) {
      setErrors({
        email: emailError,
        cpf: cpfError,
        cellphone: cellphoneError
      })
      notify('Verifique suas informações e tente novamente.')
      return;
    }

    setDisabledFields(true);
    setDisabledAction(true);
    setStep('captcha');
    recaptchaRef.current = new window.firebase.auth.RecaptchaVerifier('recaptcha-container', { 'callback': onRecaptcha });
    recaptchaRef.current.render();
  }

  const onSmsConfirmed = async (idToken) => {
    // setLoading(false);
    try {
      const result = await Parse.Cloud.run(
        'downloadDocument', 
        { cpf, email, celular: cellphone, idToken }
      )
      setLoading(false)
      notify('Sucesso! Verifique a caixa de entrada do seu email para fazer o download do seu documento.', 'success')
    } catch(error) {
      setLoading(false);
      notify(error.message, 'warning')
    }
  }

  const onSendButtonSmsStep = () => {
    setLoading(true);
    window.confirmationResult.confirm(smsCode.replace(/\D/gi, ''))
      .then(result => result.user.getIdToken())
      .then(onSmsConfirmed)
      .catch(error => {
        console.log('sms error', error);
        notify('Não foi possível verificar o seu código SMS. Tente novamente.')
        setLoading(false);
      })
  }

  const onSendButton = (e) => {
    e.preventDefault();
    if(step === 'initial') {
      onSendButtonInitialStep();
    } else if(step === 'sms') {
      onSendButtonSmsStep();
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Grid container spacing={3} direction="column" justify="center" alignItems="center" className={classes.container}>
        <Grid item xs={12} md={6} lg={4} xl={4} className={classes.centerFlex}>
          <img src="885eb8baac324f0ba3c3c3a6618fbebc.png" className={classes.logo} />

          <Card className={classes.root}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                DOWNLOAD
              </Typography>
              <Typography variant="h4" component="h2">
                Contra-cheque
              </Typography>
              {/* <Typography className={classes.pos} color="textSecondary">
                adjective
              </Typography> */}
              <Typography variant="body2" component="p" className={classes.textDescription}>
                Quer obter o seu último Contra-cheque? Digite seu CPF, e-mail e celular para ter acesso ao documento. Você receberá um código. Após a confirmação dos dados, o link para o download será enviado para o seu e-mail.
              </Typography>

              <InputMask
                mask="999.999.999-99"
                value={cpf}
                onChange={e => updateCpf(e.target.value)}
                error={errors.cpf != null}
                helperText={errors.cpf}
                disabled={disabledFields}
              >
                <TextField 
                  fullWidth
                  className={classes.textField}
                  label="CPF" 
                  variant="filled"
                  error={errors.cpf != null}
                  helperText={errors.cpf}
                />
              </InputMask>

              <InputMask
                mask="(99) 9 9999-9999"
                value={cellphone}
                onChange={e => updateCellphone(e.target.value)}
                disabled={disabledFields}
              >
                <TextField 
                  fullWidth
                  className={classes.textField}
                  label="Celular (ex: 61988889999)" 
                  error={errors.cellphone != null}
                  helperText={errors.cellphone}
                  variant="filled"
                />
              </InputMask>

              <TextField 
                fullWidth
                className={classes.textField}
                label="Email" 
                variant="filled"
                value={email}
                onChange={e => updateEmail(e.target.value)}
                error={errors.email != null}
                helperText={errors.email}
                disabled={disabledFields}
              />

              <InputMask
                mask="9 9 9 9 9 9"
                value={smsCode}
                onChange={e => updateSmsCode(e.target.value)}
                disabled={loading}
              >
                <TextField 
                  fullWidth
                  className={classes.textField}
                  style={{ display: step !== 'sms' ? 'none' : undefined }}
                  label="Código de Confirmação (SMS)" 
                  error={errors.sms != null}
                  helperText={errors.sms}
                  variant="filled"
                />
              </InputMask>

              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                <div id="recaptcha-container" style={{ display: step === 'captcha' ? 'block' : 'none' }}></div>
              </div>
            </CardContent>
            <CardActions className={classes.centerFlex}>
              <Button 
                fullWidth 
                size="large"
                variant="contained" 
                color="primary"
                onClick={onSendButton}
                disabled={disabledAction || loading}
              >
                {loading 
                  ? 'CARREGANDO...' : 
                  (step === 'sms' ? 'CONFIRMAR CÓDIGO' : 'ENVIAR')
                }
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Notification />
      </Grid>
    </MuiThemeProvider>
  );
}