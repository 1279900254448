import React from 'react';
import "./App.css";
import polyglotI18nProvider from 'ra-i18n-polyglot';

import { Admin, Resource, ListGuesser } from 'react-admin';
import {ParseAuth, ParseClient} from 'ra-data-parse'

import DocumentsIcon from '@material-ui/icons/Book';
import ColaboradoresIcon from '@material-ui/icons/Person';
import ImportsIcon from '@material-ui/icons/Publish';

import LoginPage from './components/LoginPage';
import ptBrMessages from './i18n/pt-br';
import theme from './styles/theme';
import DocumentUpload from './components/DocumentUpload';
import DocumentList from './components/DocumentList';
import ColaboradoresList from './components/ColaboradoresList';
import ImportsList from './components/ImportsList';
import { Route } from 'react-router-dom';
import DownloadPage from './components/DownloadPage';
import CSVImport from './components/CSVImport';

const messages = {
    'pt-br': ptBrMessages,
};
const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'pt-br');

const parseConfig = {
  URL: 'https://cc.finatec.org.br/api',
  APP_ID: 'finatec_cheque'
}

const dataProvider = ParseClient(parseConfig);
const authProvider = ParseAuth(parseConfig);

const App = ({  }) => {
  return (
    <Admin 
      theme={theme}
      dataProvider={dataProvider} 
      i18nProvider={i18nProvider}
      authProvider={authProvider}
      loginPage={LoginPage}
      customRoutes={[
        <Route
          path="/download/contracheque"
          component={DownloadPage}
          noLayout
        />
      ]}
    >
      {/* <Resource name="_User" list={ListGuesser} options={{ label: "Usuários" }} /> */}
      <Resource name="Documentos" list={DocumentList} icon={DocumentsIcon} create={DocumentUpload}  />
      <Resource name="Colaborador" list={ColaboradoresList} icon={ColaboradoresIcon} options={{ label: 'Colaboradores' }} />
      <Resource name="Import" list={ImportsList} icon={ImportsIcon} create={CSVImport} options={{ label: 'Importações' }} />
    </Admin>
  )
}

export default App;
