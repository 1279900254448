import React from 'react';
import { List, Datagrid, TextField } from 'react-admin'

const ImportsList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="file._name" label="Arquivo" />
      <TextField source="total" label="Total de Linhas" />
      <TextField source="succeded" label="Inseridas com sucesso" />
      <TextField source="failed" label="Falha na inserção" />
      {/* <TextField source="succeeded" label="Taxa de sucesso" /> */}
    </Datagrid>
  </List>
);

export default ImportsList;