import React from 'react';
import { List, Datagrid, TextField } from 'react-admin'

const ColaboradoresList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="cpf" label="CPF" />
      <TextField source="nome" label="Nome Completo" />
      <TextField source="email" label="Email" />
      <TextField source="telefone" label="Telefone" />
      <TextField source="celular" label="Celular" />
    </Datagrid>
  </List>
);

export default ColaboradoresList;